export const fonts = {
  body: 'System',
  heading: 'System',
  monospace: 'System',
  // body: "Oswald_400Regular",
  // heading: "Lato_400Regular",
  // monospace: "Oswald_400Regular",
};

export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
};

export const fontSizes = {
  tiny: '10px',
  caption: '12px',
  button: '14px',
  body: '16px',
  title: '20px',
  heading: '32px',
  h5: '24px',
  h4: '34px',
  h3: '45px',
  h2: '56px',
  h1: '112px',
};
