import React, { useContext, useState } from 'react';
import { Alert } from 'react-native';

import { AccountButton } from 'components/AccountButton';
import { AccountBackground } from 'components/AccountBackground';
import { AccountContainer } from 'components/AccountContainer';
import { AccountLink } from 'components/AccountLink';
import { AuthFields } from 'components/AuthFields';
import { AuthInput } from 'components/AuthInput';
import { AuthInputBorder } from 'components/AuthInputBorder';
import { ErrorContainer } from 'components/ErrorContainer';
import { Spacer } from 'components/Spacer';
import { Text } from 'components/Text';

import { AuthenticationContext } from 'contexts/AuthenticationContext';

import { ActivityIndicator } from 'react-native-paper';
import { colors } from '../../theme/colors';

export const UserForgotPasswordScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const { onResetPasswordRequest, isLoading, error } = useContext(
    AuthenticationContext,
  );
  const isSubmittable = () => {
    return email !== '';
  };

  return (
    <AccountBackground>
      <AccountContainer>
        <Spacer size="heading" position="bottom">
          <Text variant="accountHeading">Reset Password</Text>
        </Spacer>
        <AuthFields>
          <AuthInputBorder>
            <AuthInput
              value={email}
              placeholder="Username"
              autoComplete="username"
              keyboardType="email-address"
              textContentType="username"
              autoCapitalize="none"
              returnKeyType="next"
              onChangeText={setEmail}
            />
          </AuthInputBorder>
        </AuthFields>
        <AccountLink onPress={() => navigation.goBack()}>Back to login.</AccountLink>
        {!error && (
          <ErrorContainer size="large">
            <Text variant="error">{error}</Text>
          </ErrorContainer>
        )}
        <Spacer size="large">
          {!isLoading ? (
            <AccountButton
              title={'Email Me'}
              isDisabled={!isSubmittable()}
              onPress={() => {
                onResetPasswordRequest(email);
                Alert.alert(
                  'Reset Password',
                  'An email has been sent, containing instructions for resetting your password.',
                  error,
                  [{ text: 'OK', onPress: null }],
                );
                navigation.goBack();
              }}
            />
          ) : (
            <ActivityIndicator animating={true} color={colors.headfirst.green} />
          )}
        </Spacer>
      </AccountContainer>
    </AccountBackground>
  );
};
