import React from 'react';

import { Background } from 'components/Background';
import { BarHeaderLarge } from 'components/BarHeaderLarge';
import { VerticalScroll } from 'components/VerticalScroll';

import { RoundSummaryJuniorClubs } from './RoundSummaryJuniorClubs';
import { SeasonSummaryJuniorClubs } from './SeasonSummaryJuniorClubs';
import { RoundSummarySeniorClubs } from './RoundSummarySeniorClubs';
import { SeasonSummarySeniorClubs } from './SeasonSummarySeniorClubs';
import { AllSeasonsOverviewJuniorClubs } from './AllSeasonsOverviewJuniorClubs';
import { ExportAllReportsData } from './ExportAllReportsData';

export const InsightsScreen = ({ navigation }) => {
  return (
    <Background>
      <BarHeaderLarge title="Insights" />
      <VerticalScroll>
        <RoundSummaryJuniorClubs />
        <SeasonSummaryJuniorClubs />
        <RoundSummarySeniorClubs />
        <SeasonSummarySeniorClubs />
        <AllSeasonsOverviewJuniorClubs />
        <ExportAllReportsData />
      </VerticalScroll>
    </Background>
  );
};
