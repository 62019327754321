import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createDocument, getDocuments, updateDocument, deleteDocument } from 'api/DocumentsApi';

export const useGetDocuments = ({ clubId }) => {
  return useQuery({
    queryKey: queryKey({ clubId }),
    queryFn: getDocuments,
    refetchInterval: 1000 * 60, // every minute
    enabled: !!clubId
  });
};

export const useCreateDocument = ({ clubId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createDocument,
    onSuccess: async (data) => {
      if (!clubId) return;
      refreshDocuments({ queryClient, clubId });
    }
  });
};

export const useUpdateDocument = ({ clubId, playerId }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateDocument,
    onSuccess: (data) => {
      refreshDocuments({ queryClient, clubId });
    },
  });
};

export const useDeleteDocument = ({ clubId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteDocument,
    onSuccess: async (data) => {
      refreshDocuments({ queryClient, clubId });
    },
  })
};

export const refreshDocuments = ({ queryClient, clubId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ clubId })
  });
};

const queryKey = ({ clubId, documentId }) => {
  if (documentId) {
    return ['clubs', clubId, 'documents', documentId];
  } else {
    return ['clubs', clubId, 'documents'];
  }
}