import styled from 'styled-components/native';

export const Container = styled.View`
  flex: 1
  justify-content: center;
  align-items: center;
`;

export const PressableButton = styled.Pressable`
  margin-vertical: 8px;
  margin-horizontal: 16px;
  height: 47px;
  background-color: ${(props) => props.theme.colors.headfirst.green};
  border-radius: 4px;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1.0)};
`;

export const LabelText = styled.Text`
  color: ${(props) => props.theme.colors.pure.white};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";