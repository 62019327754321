import styled from 'styled-components/native';

// import PlusPNG from '../../../../assets/icons/add-full_blue.png';
import PlusPNG from '../../../assets/icons/plus-orange.png';
export const PlusIcon = styled.Image.attrs({
  source: PlusPNG,
})`
  width: 24px;
  height: 24px;
`;

export const RefreshText = styled.Text`
  color: ${(props) => props.theme.colors.headfirst.green};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
  margin-horizontal: 8px;
`;

export const BarButton = styled.Pressable`
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 4px;
`;
