import styled from 'styled-components/native';

export const RefreshText = styled.Text`
  color: ${(props) => props.theme.colors.headfirst.green};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
  margin-horizontal: 8px;
`;

export const BarButton = styled.Pressable`
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 4px;
`;

export const RefreshControl = styled.RefreshControl``;

export const MessageList = styled.SectionList.attrs({
  contentContainerStyle: {
    paddingTop: 0,
  },
})`
  margin: 0;
  background-color: white;
`;

export const ListHeaderContainer = styled.View`
  padding-horizontal: 16px;
  background-color: rgba(255, 255, 255, 0.75);
`;

export const ListHeaderText = styled.Text`
  padding-top: 16px;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.colors.headfirst.green};
`;
