import React from 'react';
import { useWindowDimensions } from 'react-native';

export const sizeClasses = { regular: 0, compact: 1 };

export const SizeClassView = ({ size, children }) => {
  const { width, scale } = useWindowDimensions();
  const isSizeClass = (s) => {
    if (width / scale < 250) {
      return size === sizeClasses.compact;
    } else {
      return size === sizeClasses.regular;
    }
  };

  return <>{isSizeClass(size) && children}</>;
};
