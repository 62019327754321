import styled from 'styled-components/native';
import { DataTable } from 'react-native-paper';

// import PlusPNG from '../../../../assets/icons/add-full_blue.png';
import PlusPNG from '../../../assets/icons/plus-orange.png';
export const PlusIcon = styled.Image.attrs({
  source: PlusPNG,
})`
  width: 24px;
  height: 24px;
`;

export const Container = styled.View`
  flex: 1;
`;

export const TrainerContainer = styled.View`
  flex-direction: column;
  gap: 4px;
  padding-vertical: 8px;
`;

export const TrainerItem = styled.Text`
`;

export const IconText = styled.Text`
  color: black;
  font-size: 15px;
  font-weight: 700;
  padding-horizontal: 4px;
`;

export const RefreshText = styled.Text`
  color: ${(props) => props.theme.colors.headfirst.green};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
  margin-horizontal: 8px;
`;

export const BarButton = styled.Pressable`
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 4px;
`;

export const DataTableView = styled(DataTable)`
  background-color: white;
  flex: 1;
`;

export const TrainersList = styled.FlatList``;
export const RefreshControl = styled.RefreshControl``;
