import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Platform, View } from 'react-native';
import { BarMenu } from 'components/BarMenu';
import { DropDownList } from 'components/DropDownList';
import { BarButton, BarTitleText, DownloadText } from './styles';

import { ProfileContext } from 'contexts/ProfileContext';
import { useGetRounds } from 'hooks/useRound';
import { useGetSeasons } from 'hooks/useSeason';
import { useGetReportsCSV } from '../../../hooks/useReport';

import { colors } from 'theme/colors';
import { Text } from '../../../components/Text';

export const ExportAllReportsData = () => {
  const [activeDropDown, setActiveDropDown] = useState('');
  const [seasonId, setSeasonId] = useState('');
  const [roundFromId, setRoundFromId] = useState('');
  const [roundToId, setRoundToId] = useState('');

  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => { return currentClub?.club?.id }, [currentClub]);
  const league = useMemo(() => { return currentClub?.club?.affiliations[0] }, [currentClub]);

  const { data: seasons } = useGetSeasons({ leagueId: league?.leagueId });
  const { data: rounds } = useGetRounds({ leagueId: league?.leagueId });

  const { data: reportReportsCSV, refetch: refreshReportsCSV, isError: isErrorReportsCSV, isFetching: isFetchingReportsCSV } = useGetReportsCSV({ clubId, seasonId, fromRoundId: roundFromId, toRoundId: roundToId })
  const [requestReportsCSV, setRequestReportsCSV] = useState(false);
  const [roundToBorderColor, setRoundToBorderColor] = useState(colors.border)
  const [roundFromBorderColor, setRoundFromBorderColor] = useState(colors.border)

  const seasonItems = useMemo(() => {
    if (!seasons) return [];

    return [
      { id: '', name: 'All Seasons', },
      ...seasons
        .map((season) => { return { id: season.id, name: `Season ${season.name}` } })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0)
    ];
  }, [seasons])

  useEffect(() => {
    if (seasons?.length == 0) return;
    if (!seasonId) {
      setSeasonId(seasons[seasons?.length - 1]?.id);
    }
  }, [seasons]);

  const roundItems = useMemo(() => {
    if (!rounds) return [];

    var results = rounds;

    if (seasonId) {
      const seasonIndex = seasons.findIndex((season) => season.id === seasonId);
      var seasonName = seasons[seasonIndex]?.name;
      results = results.filter((result) => result.season?.name?.includes(seasonName))
    } else {
      results = []
    }
    results = results.map((round) => { return { id: round.id, name: round.name } })
    return [
      { id: '', name: '', },
      ...results,
    ];
  }, [rounds, seasonId])

  useEffect(() => {
    if (!requestReportsCSV) return;
    if (isFetchingReportsCSV) return;
    setRequestReportsCSV(false);
    if (isErrorReportsCSV) return;
    if (!!reportReportsCSV) {
      const clubAbbreviation = currentClub?.club?.abbreviation ?? "Club";
      const roundIndex = rounds.findIndex((round) => round.id === roundFromId);
      var filename = '';
      if (!!seasonId) {
        const seasonIndex = seasons.findIndex((season) => season.id === seasonId);
        const seasonName = seasons[seasonIndex].name;
        filename = `${clubAbbreviation} - Season ${seasonName} Reports.csv`;
      } else {
        filename = `${clubAbbreviation} - All Seasons Reports.csv`;
      }

      if (Platform.OS === 'web') {
        const link = document.createElement('a');
        link.href = 'data:application/octet-stream,' + encodeURIComponent(reportReportsCSV);
        link.download = filename;
        document.body.append(link);
        link.click();
        link.remove();
      } else {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const fileUri = `${FileSystem.documentDirectory}/${encodeURIComponent(filename)}`;
          await FileSystem.writeAsStringAsync(fileUri, fileReader.result.split(',')[1], { encoding: FileSystem.EncodingType.Base64 });
          await Share.share({
            message: filename,
            title: "Share file",
            url: fileUri,
          });
        }
        fileReader.readAsDataURL(reportReportsCSV);
      }
      setRequestReportsCSV(false);
    }
  }, [reportReportsCSV, isFetchingReportsCSV])

  const downloadReportCSV = () => {
    setRoundFromBorderColor(colors.border);
    setRoundToBorderColor(colors.border);
    if (!!seasonId) {
      if (!!roundFromId || !!roundToId) {
        if (!roundFromId) {
          setRoundFromBorderColor('red');
        }
        if (!roundToId) {
          setRoundToBorderColor('red');
        }
        if (!roundFromId || !roundToId) {
          return;
        }
      }
    }

    setRequestReportsCSV(true);
    refreshReportsCSV();
  };

  return (
    <>
      {league?.insights?.exportAllReportsDataCsv && (
        <BarMenu>
          <BarButton onPress={downloadReportCSV}>
            <DownloadText style={{ color: colors.headfirst.green }}>Export to CSV</DownloadText>
          </BarButton>
          <View style={{ flex: 1 }}></View>
          <DropDownList
            placeholder="To Round"
            items={roundItems}
            enabled={!!seasonId}
            isActive={activeDropDown == 'rounds2'}
            onOpen={() => {
              setActiveDropDown('rounds');
            }}
            value={roundToId}
            setValue={setRoundToId}
            onChangeValue={(roundId) => {
              setRoundToId(roundId);
            }}
            style={{
              marginLeft: 8,
              borderColor: `${roundToBorderColor}`
            }}
            containerStyle={{
              width: 200, marginHorizontal: 4
            }}
          />
          <DropDownList
            placeholder="From Round"
            items={roundItems}
            enabled={!!seasonId}
            isActive={activeDropDown == 'rounds3'}
            onOpen={() => {
              setActiveDropDown('rounds');
            }}
            value={roundFromId}
            onChangeValue={(roundId) => {
              setRoundFromId(roundId);
            }}
            style={{
              marginLeft: 8,
              borderColor: `${roundFromBorderColor}`
            }}
            containerStyle={{
              width: 200, marginHorizontal: 4
            }}
          />
          <DropDownList
            placeholder="All Seasons"
            items={seasonItems}
            isActive={activeDropDown == 'seasons'}
            onOpen={() => {
              setActiveDropDown('seasons');
            }}
            value={seasonId}
            onChangeValue={(seasonId) => {
              setSeasonId(seasonId);
              if (!seasonId) {
                setRoundFromId(null);
                setRoundToId(null);
              }
            }}
            style={{
              marginLeft: 8,
              marginRight: 16,
            }}
            containerStyle={{
              width: 200,
              marginHorizontal: 4
            }}
          />
          <BarTitleText>Export All Reports Data</BarTitleText>
        </BarMenu>
      )}
    </>
  )
}