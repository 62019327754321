import { Platform } from 'react-native';
import styled from 'styled-components/native';

const isAndroid = Platform.OS === 'android';

export const AvoidingView = styled.KeyboardAvoidingView.attrs({
  behavior: isAndroid ? null : 'padding',
  keyboardVerticalOffset: isAndroid ? 0 : 48,
})`
  flex: 1;
`;
