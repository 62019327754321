import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Platform, View } from 'react-native';
import { BarMenu } from 'components/BarMenu';
import { DropDownList } from 'components/DropDownList';
import { BarButton, BarTitleText, DownloadText } from './styles';

import { ProfileContext } from 'contexts/ProfileContext';
import { useGetSeasons } from 'hooks/useSeason';
import { useGetJuniorClubAllSeasonsPdf } from 'hooks/useReport';
import { colors } from 'theme/colors';

export const AllSeasonsOverviewJuniorClubs = () => {
  const [activeDropDown, setActiveDropDown] = useState('');
  const [seasonFromId, setSeasonFromId] = useState('');
  const [seasonToId, setSeasonToId] = useState('');
  const [excludePreseason, setExcludePreseason] = useState(false);

  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => { return currentClub?.club?.id }, [currentClub]);
  const league = useMemo(() => { return currentClub?.club?.affiliations[0] }, [currentClub]);

  const { data: seasons } = useGetSeasons({ leagueId: league?.leagueId });

  const { data: reportAllSeasonsPdf, refetch: refreshAllSeasonsPdf } = useGetJuniorClubAllSeasonsPdf({ clubId, fromSeasonId: seasonFromId, toSeasonId: seasonToId })
  const [requestAllSeasonsPDF, setRequestAllSeasonsPDF] = useState(false);
  const [seasonToBorderColor, setSeasonToBorderColor] = useState(colors.border)
  const [seasonFromBorderColor, setSeasonFromBorderColor] = useState(colors.border)

  const seasonItems = useMemo(() => {
    if (!seasons) return [];

    return [
      { id: null, name: 'Choose a Season', },
      ...seasons
        .map((season) => { return { id: season.id, name: `Season ${season.name}` } })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0)
    ];
  }, [seasons])

  useEffect(() => {
    if (!requestAllSeasonsPDF) return;
    setRequestAllSeasonsPDF(false);
    if (!!reportAllSeasonsPdf) {
      const clubAbbreviation = currentClub?.club?.abbreviation || currentClub?.club?.name || "Club";
      const filename = `${clubAbbreviation} - All Seasons Overview.pdf`;

      if (Platform.OS === 'web') {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(reportAllSeasonsPdf);
        link.download = filename;
        document.body.append(link);
        link.click();
        link.remove();
      } else {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const fileUri = `${FileSystem.documentDirectory}/${encodeURIComponent(filename)}`;
          await FileSystem.writeAsStringAsync(fileUri, fileReader.result.split(',')[1], { encoding: FileSystem.EncodingType.Base64 });
          await Share.share({
            message: filename,
            title: "Share file",
            url: fileUri,
          });
        }
        fileReader.readAsDataURL(reportAllSeasonsPdf);
      }
    }
  }, [reportAllSeasonsPdf])

  const downloadAllSeasonsPDF = () => {
    setSeasonFromBorderColor(colors.border);
    setSeasonToBorderColor(colors.border);
    if (!seasonFromId) {
      setSeasonFromBorderColor('red');
    }
    if (!seasonToId) {
      setSeasonToBorderColor('red');
    }
    if (!seasonFromId || !seasonToId) {
      return;
    }

    setRequestAllSeasonsPDF(true);
    refreshAllSeasonsPdf();
  };

  return (
    <>
      {league?.insights?.allSeasonsOverviewJuniorClubPdf && (
        <BarMenu>
          <BarButton onPress={downloadAllSeasonsPDF}>
            <DownloadText style={{ color: colors.headfirst.green }}>Print PDF</DownloadText>
          </BarButton>
          <View style={{ flex: 1 }}></View>
          <DropDownList
            placeholder="To Season"
            items={seasonItems}
            isActive={activeDropDown == 'seasonTo'}
            onOpen={() => {
              setActiveDropDown('seasonTo');
            }}
            value={seasonToId}
            setValue={setSeasonToId}
            onChangeValue={(seasonId) => {
              setSeasonToId(seasonId);
            }}
            style={{
              marginLeft: 8,
              borderColor: `${seasonToBorderColor}`
            }}
            containerStyle={{
              width: 200, marginHorizontal: 4
            }}
          />
          <DropDownList
            placeholder="From Season"
            items={seasonItems}
            isActive={activeDropDown == 'seasonFrom'}
            onOpen={() => {
              setActiveDropDown('seasonFrom');
            }}
            value={seasonFromId}
            setValue={setSeasonFromId}
            onChangeValue={(seasonId) => {
              setSeasonFromId(seasonId);
            }}
            style={{
              marginLeft: 8,
              borderColor: `${seasonFromBorderColor}`
            }}
            containerStyle={{
              width: 200, marginHorizontal: 4
            }}
          />
          <BarTitleText>All Seasons Overview - Junior Clubs</BarTitleText>
        </BarMenu>
      )}
    </>
  )
}