import styled from 'styled-components/native';

const defaultTextStyles = (theme) => `
  font-family: ${theme.fonts.body};
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.text.primary};
  flex-wrap: wrap;
  margin-top: 0px;
  margin-bottom: 0px;
`;

const body = (theme) => `
    font-size: ${theme.fontSizes.body};
`;

const date = (theme) => `
  height: 24px;
  width: 253px;
  color: #000000;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

const hint = (theme) => `
    font-size: ${theme.fontSizes.body};
`;

const error = (theme) => `
    color: ${theme.colors.text.error};
`;

const caption = (theme) => `
    font-size: ${theme.fontSizes.caption};
    font-weight: ${theme.fontWeights.bold};
`;

const label = (theme) => `
    font-family: ${theme.fonts.heading};
    font-size: ${theme.fontSizes.body};
    font-weight: ${theme.fontWeights.medium};
`;

const accountHeading = (theme) => `
    font-family: ${theme.fonts.heading};
    font-size: ${theme.fontSizes.heading};
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.text.inverse};
`;

const heading = (theme) => `
    font-family: ${theme.fonts.heading};
    font-size: ${theme.fontSizes.heading};
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.pure.black};
`;

const trademark = (theme) => `
    font-family: ${theme.fonts.body};
    font-size: ${theme.fontSizes.tiny};
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colors.text.inverse};
`;

const variants = {
  accountHeading,
  body,
  caption,
  date,
  error,
  heading,
  hint,
  label,
  trademark,
};

export const Text = styled.Text`
  ${({ theme }) => defaultTextStyles(theme)}
  ${({ variant, theme }) => variants[variant](theme)}
`;

Text.defaultProps = {
  variant: 'body',
};
