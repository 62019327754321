import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  margin-vertical: 8px;
`;

export const LabelContainer = styled.View`
  width: 100%;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
  padding-bottom: 8px;
`;

export const LabelText = styled.Text`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  padding-horizontal: 16px;
`;
// font-family: "SF Pro Display";

export const RowContainer = styled.View`
  width: 100%;
  flex-direction: row;
  padding-horizontal: 16px;
  padding-vertical: 16px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

export const RowLabelText = styled.Text.attrs({
  numberOfLines: 1,
})`
  color: #000000;
  padding-horizontal: 6px;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

export const RowIconContainer = styled.View`
  width: 24px;
  height: 24px;
  justify-content: center;
`;

// import Check from "../../../assets/icons/check.svg";
// export const Icon = styled(Check).attrs({ width: 16, height: 16 })``;
import CheckPNG from '../../../assets/icons/check.png';
export const CheckIcon = styled.Image.attrs({
  source: CheckPNG,
})`
  width: 18px;
  height: 15px;
  tint-color: ${(props) => props.theme.colors.headfirst.orange};
`;
export const Icon = styled(CheckIcon)``;

export const PressableRow = styled.Pressable``;

export const TableRowsList = styled.View.attrs({
  contentContainerStyle: {
    paddingTop: 0,
  },
})``;
