import 'react-native-gesture-handler';
import React from 'react';
import ExpoStatusBar from 'expo-status-bar/build/ExpoStatusBar';
import { LogBox } from 'react-native';
import * as Linking from 'expo-linking';

import { ThemeProvider } from 'styled-components/native';
import { theme } from './src/theme';
import { Navigation } from './src/navigation';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';

import { GlobalStateContextProvider } from 'contexts/GlobalStateContext';
import { AuthenticationContextProvider } from 'contexts/AuthenticationContext';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient();

const linking = {
  prefixes: [
    Linking.createURL('/'),
    'https://clubportal.headfirstapp.com',
    'https://clubportal.staging.headfirstapp.com',
    'exp://192.168.1.107:19000',
  ],
  config: {
    screens: {
      CreatePassword: 'create-new-password',
    },
  },
};

export default function App() {
  LogBox.ignoreLogs([
    "[react-native-gesture-handler] Seems like you're using an old API with gesture components, check out new Gestures system!",
  ]);

  return (
    <>
      <GlobalStateContextProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <AuthenticationContextProvider>
              <ActionSheetProvider>
                <Navigation linking={linking} />
              </ActionSheetProvider>
            </AuthenticationContextProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </GlobalStateContextProvider>
      <ExpoStatusBar style="light" />
    </>
  );
}
