import React, { useEffect, useMemo, useState } from 'react';
import { Keyboard, Platform, RefreshControl } from 'react-native';
import styled from 'styled-components/native';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import { useActionSheet } from '@expo/react-native-action-sheet';

import { Alert } from 'components/Alert';
import { ButtonBlue } from 'components/ButtonBlue';
import { ButtonWhite } from 'components/ButtonWhite';
import { Centered } from 'components/Centered';
import { FormFieldText } from 'components/FormFieldText';
import { CardMessage } from 'components/CardMessage';
import { Loading } from 'components/Loading';

import {
  useGetReportFiles,
  useCreateReportFile,
  useDeleteReportFile,
  useUpdateReportFile
} from 'hooks/useReportFile';

import { openLink } from 'utils/links';

import PlusPNG from '../../../assets/icons/plus-orange.png';
import { Confirm } from 'components/Confirm';

const PlusIcon = styled.Image.attrs({
  source: PlusPNG,
})`
  width: 24px;
  height: 24px;
`;

const TitleRow = styled.View`
  flex-direction: row-reverse;
  align-items: center;
  padding-top: ${(props) => props.theme.space[1]};
  height: 50px;
`;

const TitleRowWhite = styled.View`
  flex-direction: row-reverse;
  align-items: center;
  padding-top: ${(props) => props.theme.space[1]};
  background-color: white;
  height: 50px;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

const TitleRowButton = styled.Pressable`
  padding-vertical: 4px;
  padding-right: 16px;
`;

const EditText = styled.Text`
  color: ${(props) => props.theme.colors.headfirst.green};
  font-size: 15px;
  font-weight: bold;
`;

const DocumentsList = styled.FlatList`
  padding-bottom: 80px;
`;
const PressRow = styled.Pressable``;

export const ReportFiles = ({ clubId, reportId }) => {
  const { data: reportFiles, isLoading } = useGetReportFiles({ clubId, reportId });
  const { mutate: createReportFile, isPending: isCreating, isSuccess: hasCreated, isError: isErrorCreate, error: createError } = useCreateReportFile({ clubId, reportId });
  const { mutate: updateReportFile, isPending: isUpdating, isSuccess: hasUpdated, isError: isErrorUpdate, error: updateError } = useUpdateReportFile({ clubId, reportId });
  const { mutate: deleteReportFile, isPending: isDeleting, isSuccess: hasDeleted, isError: isErrorDelete, error: deleteError } = useDeleteReportFile({ clubId, reportId });

  const { showActionSheetWithOptions } = useActionSheet();

  const [id, setId] = useState(null);
  const [file, setFile] = useState({});
  const [filename, setFilename] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const onPressPickOptions = () => {
    const options = ['Document', 'Photo Gallery', 'Cancel'];
    const cancelButtonIndex = 2;

    showActionSheetWithOptions({
      options,
      cancelButtonIndex
    }, (selectedIndex) => {
      switch (selectedIndex) {
        case 0:
          pickFile()
          break;
        case 1:
          pickImage()
          break;
        case cancelButtonIndex:
        // Canceled
      }
    });
  }

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      allowsMultipleSelection: false,
      quality: 0.5,
    });

    if (!result.canceled && result.assets.length == 1) {
      setFile(result?.assets[0])
      setFilename(result?.assets[0].fileName);
      if (!title) {
        setTitle(result?.assets[0].fileName);
      }
    }
  };

  const pickFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (!result.canceled && result.assets.length == 1) {
      setFile(result.assets[0]);
      setFilename(result.assets[0].name);
      if (!title) {
        setTitle(result.assets[0].name);
      }
    }
  };

  const canSave = useMemo(() => {
    return !(file == {}) && title !== '';
  })

  const saveReportFile = () => {
    const reportFile = { id, title, description, file };

    if (id) {
      updateReportFile({ clubId, reportId, reportFile });
    } else {
      createReportFile({ clubId, reportId, reportFile });
    }
  }

  const stopEditingFileFields = () => {
    setId(null)
    setFile({});
    setFilename('');
    setTitle('');
    setDescription('');
    setShowForm(false);
    setEditMode(false);
  };

  useEffect(() => {
    if (hasCreated || hasUpdated || hasDeleted) {
      stopEditingFileFields()
    }
  }, [hasCreated, hasUpdated, hasDeleted]);

  useEffect(() => {
    if (createError) {
      Alert('Something went wrong', createError?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
    if (updateError) {
      Alert('Something went wrong', updateError?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
    if (deleteError) {
      Alert('Something went wrong', updateError?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [isErrorCreate, isErrorUpdate, isErrorDelete]);

  return (
    <>
      <Centered>
        {!showForm ? (
          <>
            <TitleRow>
              <TitleRowButton onPress={() => { setShowForm(true); }}>
                <PlusIcon />
              </TitleRowButton>
            </TitleRow>
            <TitleRowWhite>
              <TitleRowButton onPress={() => { setEditMode(!editMode); }}>
                <EditText>{editMode ? 'Cancel' : 'Edit'}</EditText>
              </TitleRowButton>
            </TitleRowWhite>
            <DocumentsList
              data={reportFiles}
              renderItem={({ item }) => (
                <PressRow
                  onPress={() => {
                    openLink(item?.file?.url);
                  }}
                >
                  <CardMessage
                    title={item?.title}
                    date={item?.updatedAtShort}
                    isEditing={editMode}
                    onDelete={() => {
                      Confirm('Confirm Delete', `Delete ${item?.file?.filename}`, () => {
                        deleteReportFile({ clubId, reportId, file: item });
                        setEditMode(false);
                      }, () => {
                        setEditMode(false);
                      });
                    }}
                    onUpdate={() => {
                      setShowForm(true);
                      setId(item?.id);
                      setTitle(item?.title);
                      setDescription(item?.description);
                    }}
                  >
                    {item?.description}
                  </CardMessage>
                </PressRow>
              )}
              keyExtractor={(item) => item.id}
              refreshControl={
                <RefreshControl
                  refreshing={isLoading}
                  onRefresh={() => {
                    // onRetrieve();
                  }}
                />
              }
            />
          </>
        ) : (
          <>
            <ButtonWhite label={id ? "Replace file" : "Pick a file"} onPress={onPressPickOptions} />
            <FormFieldText label="Filename"
              value={title}
              onChangeText={setTitle} />
            <FormFieldText
              label="Description"
              value={description}
              onChangeText={setDescription}
            />
            <ButtonBlue
              label={id ? 'Update' : 'Upload'}
              isDisabled={!canSave}
              onPress={() => {
                Keyboard.dismiss();
                saveReportFile()
              }}
            />
            <ButtonWhite
              label="Cancel"
              onPress={() => {
                Keyboard.dismiss();
                stopEditingFileFields();
              }}
            />
          </>
        )}
      </Centered >
      <Loading isLoading={isLoading || isCreating || isUpdating || isDeleting} />
    </>
  );
};
