import styled from 'styled-components/native';
import { DataTable } from 'react-native-paper';

export const Container = styled.View`
  flex: 1;
`;

export const DataTableView = styled(DataTable)`
  background-color: white;
  flex: 1;
`;

export const DataTableCell = styled(DataTable.Cell)``;
export const DataTableRow = styled(DataTable.Row)``;
export const DataTableHeader = styled(DataTable.Header)``;
export const DataTableTitle = styled(DataTable.Title)``;

export const DataTableTitleCenter = styled(DataTable.Title).attrs({
  justifyContent: 'center',
})``;

export const DataTableCellCenter = styled(DataTable.Cell).attrs({
  justifyContent: 'center',
})``;

export const BarTitleText = styled.Text`
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 5px;  
`;

export const RefreshText = styled.Text`
  color: ${(props) => props.theme.colors.headfirst.green};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
  margin-horizontal: 8px;
`;

export const RefreshTextDisabled = styled.Text`
  color: ${(props) => props.theme.colors.grey};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
  margin-horizontal: 8px;
`;

export const BarButton = styled.Pressable`
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 4px;
`;

export const DownloadText = styled.Text`
  color: ${(props) => props.theme.colors.headfirst.green};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding-horizontal: 8px;
  margin-horizontal: 8px;
`;