import styled from 'styled-components/native';

export const Container = styled.View``;

export const Background = styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
  padding-vertical: 8px;
`;

import LocationPNG from '../../../assets/icons/location-full_blue.png';
export const LocationIcon = styled.Image.attrs({
  source: LocationPNG,
})``;

export const Icon = styled(LocationIcon)`
  width: 16px;
  height: 16px;
`;

export const IconContainer = styled.View`
  padding-left: 16px;
  padding-right: 8px;
`;

export const Name = styled.Text.attrs({
  numberOfLines: 1,
})`
  flex: 1;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

export const Link = styled.Text`
  color: ${(props) => props.theme.colors.headfirst.green};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
  text-transform: uppercase;
  padding-horizontal: 16px;
`;
// font-family: "SF Pro Display";

export const PressableLink = styled.Pressable``;