import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import {
  DropDown,
  WebDropDown,
  WebDropDownItem
} from './styles';
import { colors } from '../../theme/colors';

export const DropDownList = ({
  placeholder,
  items: initialItems,
  value: initialValue,
  onChangeValue,
  onOpen,
  onFocus,
  onBlur,
  isActive,
  enabled = true,
  style,
  containerStyle,
  listItemContainerStyle,
  dropDownContainerStyle,
  zIndex,
  zIndexInverse,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!isActive) setOpen(false);
  }, [isActive]);

  return (
    <>
      {Platform.OS === 'web' && (
        <WebDropDown
          style={{
            margin: 0,
            // borderWidth: 0.25,
            // borderRadius: 3,
            borderColor: colors.border,
            height: 34,
            ...style
          }}
          enabled={enabled}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          onValueChange={(_value, index) => {
            onChangeValue(items[index]?.id);
          }}>
          {items.map((item) => {
            return (
              <WebDropDownItem
                value={item.id}
                label={item.name}
                key={item.name}
              />
            )
          })}
        </WebDropDown>
      )}
      {Platform.OS != 'web' && (
        <DropDown
          placeholder={placeholder}
          schema={{ value: 'id', label: 'name' }}
          items={items}
          setItems={setItems}
          value={value}
          setValue={setValue}
          onChangeValue={onChangeValue}
          open={open}
          setOpen={(openValue) => {
            setOpen(openValue);
            if (onOpen && openValue) onOpen();
          }}
          listMode={'SCROLLVIEW'}
          searchable={true}
          searchPlaceholder={"Search..."}
          searchContainerStyle={{
            backgroundColor: colors.pure.white,
            borderBottomWidth: 0.5,
            borderBottomColor: colors.border
          }}
          searchTextInputStyle={{
            borderWidth: 0
          }}
          zIndex={zIndex}
          zIndexInverse={zIndexInverse}
          style={{
            borderWidth: 0,
            minHeight: 36,
            ...style
          }}
          containerStyle={{
            zIndex: 1000,
            color: 'black',
            ...containerStyle
          }}
          listItemContainerStyle={{
            borderBottomWidth: 0.25,
            borderBottomColor: colors.border,
            ...listItemContainerStyle
          }}
          maxHeight={350}
          dropDownContainerStyle={{
            borderColor: colors.headfirst.green,
            ...dropDownContainerStyle
          }}
        />
      )}
    </>
  );
};
