import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, View, Text } from 'react-native';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { useIsFocused } from '@react-navigation/native';

import { AvoidingView } from 'components/AvoidingView';
import { Background } from 'components/Background';
import { BarHeaderLarge } from 'components/BarHeaderLarge';
import { ButtonBlue } from 'components/ButtonBlue';
import { CardNote } from 'components/CardNote';
import { Centered } from 'components/Centered';
import { Loading } from 'components/Loading';
import { MessageTextInput } from 'components/MessageTextInput';
import { sizeClasses, SizeClassView } from 'components/SizeClassView';
import {
  RefreshText,
  BarButton,
  MessageList,
  ListHeaderContainer,
  ListHeaderText
} from './styles';

import { ProfileContext } from 'contexts/ProfileContext';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { useQueryClient } from '@tanstack/react-query';
import { refreshClubChatMessages, useCreateClubChatMessage } from 'hooks/useClubChat';
import { useGetInfiniteClubChatMessages } from 'hooks/useClubChat';

import { subDays } from 'date-fns';
import { formattedISODate, formattedShortDateTime, parseDateTime } from 'utils/Dates';
import { colors } from 'theme/colors';

export const ClubChatScreen = ({ navigation, route }) => {
  const { currentClub, profile } = useContext(ProfileContext);
  const clubId = useMemo(() => { return currentClub?.club?.id }, [currentClub]);
  const { getItem, setItem } = useAsyncStorage(`chat-${clubId}`);

  const refMessageList = useRef(null);

  const [date, setDate] = useState(formattedISODate(subDays(new Date(), 28)))
  const [chatMessage, setChatMessage] = useState('');

  const queryClient = useQueryClient();
  const { isLoading, data: chatMessages, hasPreviousPage, fetchPreviousPage, hasNextPage, fetchNextPage, isError, error } = useGetInfiniteClubChatMessages({ clubId, date });
  const { mutate: createClubChatMessage, isPending: isCreating, isSuccess: hasCreated } = useCreateClubChatMessage({ clubId, date });

  const isFocused = useIsFocused();
  const [wasFocused, setWasFocused] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const [lastViewedMessageId, setLastViewedMessageId] = useState(null);
  const [showJumpToLatest, setShowJumpToLatest] = useState(false);

  useEffect(() => {
    console.log(`showJumpToLatest changed to ${showJumpToLatest}`)
  }, [showJumpToLatest])

  const readLastViewedMessageId = async () => {
    const item = await getItem();
    setLastViewedMessageId(item);
  };

  const writeLastViewedMessageId = async newValue => {
    await setItem(newValue);
    setLastViewedMessageId(newValue);
  };

  useEffect(() => {
    readLastViewedMessageId();
  }, []);


  const chatMessagesData = useMemo(() => {
    if (!chatMessages) return [];

    const totalChatMessages = chatMessages.pages.map(page => page.meta.totalCount).reduce((acc, number) => acc + number, 0);
    const items = chatMessages.pages.map(page => page.data.map(record => record.section)).flat();
    const dates = [...new Set(items)]
    var indexCount = 0
    const itemsData = dates.map((date, sectionIndex) => {
      const data = chatMessages?.pages?.map(page => page.data.filter((record) => record.section === date).map((record, recordIndex) => {
        indexCount += 1
        return {
          ...record,
          newMessageCount: indexCount - 1,
          sectionIndex,
          recordIndex,
        }
      })).flat();
      return {
        title: date,
        data,
      }
    })
    const message = itemsData[0]?.data[0];
    if (message) {
      if (isFocused) {
        if (lastMessage?.id == lastViewedMessageId) {
          writeLastViewedMessageId(message.id);
          setLastMessage(message);
        } else {
          setLastMessage(message);
        }
      } else {
        setLastMessage(message);
      }
    }
    return itemsData;
  }, [chatMessages])

  const scrollToBottom = () => {
    if (chatMessagesData.length == 0) return;

    if (refMessageList.current && typeof refMessageList.current.scrollToLocation === 'function') {
      refMessageList.current.scrollToLocation({ sectionIndex: 0, itemIndex: 0, animation: true })
    }
  }

  useEffect(() => {
    if (!isFocused && wasFocused) {
      if (lastMessage?.id !== lastViewedMessageId) {
        writeLastViewedMessageId(lastMessage?.id);
      }
    } else if (isFocused && !wasFocused) {
      readLastViewedMessageId();
      // scrollToBottom();
    }
    setWasFocused(isFocused);
  }, [isFocused, wasFocused])

  const loadNext = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (isError) {
      Alert.alert('Something went wrong', error?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [isError]);

  useEffect(() => {
    if (hasCreated) {
      setChatMessage('');
    }
  }, [hasCreated])

  const NewMessagesView = ({ item }) => {
    if (item.id !== lastViewedMessageId) return;
    if (item.id === lastMessage.id) return;

    const newMessageDescription = useMemo(() => {
      if (!item?.newMessageCount) return 'Unknown';

      if (item.newMessageCount == 1) {
        return `${item.newMessageCount} new message`
      } else {
        return `${item.newMessageCount} new messages`
      }
    }, [item]);

    return (
      <View style={{
        borderTopColor: colors.headfirst.green,
        borderTopWidth: 1,
        marginTop: 16,
        marginBottom: 8,
        marginHorizontal: 8,
      }}>
        <Text style={{ textAlign: 'center', fontSize: 10, color: colors.headfirst.green, padding: 4 }}>{newMessageDescription}</Text>
      </View>
    )
  }

  const renderItem = ({ item }) => {
    return (
      <Centered>
        <View style={{ paddingLeft: item?.user?.id === profile.id ? 48 : 0, paddingRight: item?.user?.id === profile.id ? 0 : 48, }}>
          <CardNote
            date={formattedShortDateTime(parseDateTime(item.createdAt))}
            author={`${item?.user?.firstName || ''} ${item?.user?.lastName || ''}`}
            type={item?.user?.id === profile.id ? 'me' : 'other'}
          >
            {item.content}
          </CardNote>
        </View>
        <NewMessagesView item={item} />
      </Centered>
    )
  };

  const renderSectionHeader = ({ section: { title } }) => {
    return (
      <Centered>
        <ListHeaderContainer>
          <ListHeaderText>{title}</ListHeaderText>
        </ListHeaderContainer>
      </Centered>
    )
  }

  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = 20;
    return contentOffset.y <= paddingToBottom;
  };

  const fadeInOpacity = useSharedValue(0);

  const fadeIn = () => {
    fadeInOpacity.value = withTiming(1, {
      duration: 200,
      easing: Easing.linear,
    });
  };

  const fadeOut = () => {
    fadeInOpacity.value = withTiming(0, {
      duration: 200,
      easing: Easing.linear,
    });
  };

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: fadeInOpacity.value, // Use the value directly
    };
  });

  return (
    <Background>
      <BarHeaderLarge title="Chat">
        <SizeClassView size={sizeClasses.regular}>
          <BarButton
            onPress={() => refreshClubChatMessages({ queryClient, clubId })}>
            <RefreshText>Refresh</RefreshText>
          </BarButton>
        </SizeClassView>
      </BarHeaderLarge>
      <AvoidingView>
        {chatMessagesData ? (
          <MessageList
            inverted={true}
            invertStickyHeaders={true}
            stickySectionHeadersEnabled={true}
            sections={chatMessagesData}
            ref={refMessageList}
            renderItem={renderItem}
            renderSectionFooter={renderSectionHeader}
            keyExtractor={(item, index) => {
              const key = item.id;
              return key;
            }}
            onEndReached={loadNext}
            onEndReachedThreshold={0.3}
            onScroll={({ nativeEvent }) => {
              if (isCloseToBottom(nativeEvent)) {
                fadeOut();
              } else {
                fadeIn();
              }
            }}
            scrollEventThrottle={400}
          />
        ) : (
          <View style={{ flex: 1 }} />
        )}
        <Animated.View
          style={[
            animatedStyle,
          ]}
        >
          <View style={{ width: 100, position: 'absolute', right: 8, bottom: 64 }}>
            <ButtonBlue label={"⇩"} onPress={() => {
              scrollToBottom();
            }} />
          </View>
        </Animated.View>
        <MessageTextInput
          value={chatMessage}
          onChangeText={setChatMessage}
          onPress={(content) => {
            createClubChatMessage({ clubId, content });
          }}
          isDisabled={isLoading || isCreating}
        />
      </AvoidingView>
      <Loading isLoading={isLoading || isCreating} />
    </Background>
  );
};
