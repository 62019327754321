import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Platform, View } from 'react-native';
import { BarMenu } from 'components/BarMenu';
import { DropDownList } from 'components/DropDownList';
import { BarButton, BarTitleText, DownloadText } from './styles';

import { ProfileContext } from 'contexts/ProfileContext';
import { useGetRounds } from 'hooks/useRound';
import { useGetSeasons } from 'hooks/useSeason';
import { useGetSeniorClubSeasonPdf } from 'hooks/useReport';
import { colors } from 'theme/colors';

export const SeasonSummarySeniorClubs = () => {
  const [activeDropDown, setActiveDropDown] = useState('');
  const [seasonName, setSeasonName] = useState('');
  const [roundId, setRoundId] = useState(null);
  const [roundFromId, setRoundFromId] = useState(null);
  const [roundToId, setRoundToId] = useState(null);

  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => { return currentClub?.club?.id }, [currentClub]);
  const league = useMemo(() => { return currentClub?.club?.affiliations[0] }, [currentClub]);

  const { data: seasons } = useGetSeasons({ leagueId: league?.leagueId });
  const { data: rounds } = useGetRounds({ leagueId: league?.leagueId });

  const { data: reportSeasonPdf, refetch: refreshSeasonPdf } = useGetSeniorClubSeasonPdf({ clubId, fromRoundId: roundFromId, toRoundId: roundToId })
  const [requestSeasonPDF, setRequestSeasonPDF] = useState(false);
  const [roundToBorderColor, setRoundToBorderColor] = useState(colors.border)
  const [roundFromBorderColor, setRoundFromBorderColor] = useState(colors.border)

  const seasonItems = useMemo(() => {
    if (!seasons) return [];

    return [
      ...seasons
        .map((season) => { return { id: season.name, name: `Season ${season.name}` } })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0)
    ];
  }, [seasons])

  useEffect(() => {
    if (seasons?.length == 0) return;
    if (!seasonName) {
      setSeasonName(seasons[seasons?.length - 1]?.name);
    }
  }, [seasons]);

  const roundItems = useMemo(() => {
    if (!rounds) return [];

    var results = rounds;

    if (seasonName) {
      results = results.filter((result) => result.season?.name?.includes(seasonName))
    } else {
      results = []
    }
    results = results.map((round) => { return { id: round.id, name: round.name } })
    return [
      { id: null, name: 'Choose a Round', },
      ...results,
    ];
  }, [rounds, seasonName])

  useEffect(() => {
    if (!requestSeasonPDF) return;
    setRequestSeasonPDF(false);
    if (!!reportSeasonPdf) {
      const clubAbbreviation = currentClub?.club?.abbreviation ?? "Club";
      const roundIndex = rounds.findIndex((round) => round.id === roundFromId);
      const seasonName = rounds[roundIndex]?.season?.name;
      const filename = `${clubAbbreviation} - Season ${seasonName} Summary.pdf`;

      if (Platform.OS === 'web') {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(reportSeasonPdf);
        link.download = filename;
        document.body.append(link);
        link.click();
        link.remove();
      } else {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const fileUri = `${FileSystem.documentDirectory}/${encodeURIComponent(filename)}`;
          await FileSystem.writeAsStringAsync(fileUri, fileReader.result.split(',')[1], { encoding: FileSystem.EncodingType.Base64 });
          await Share.share({
            message: filename,
            title: "Share file",
            url: fileUri,
          });
        }
        fileReader.readAsDataURL(reportSeasonPdf);
      }
    }
  }, [reportSeasonPdf])

  const downloadSeasonPDF = () => {
    setRoundFromBorderColor(colors.border);
    setRoundToBorderColor(colors.border);
    if (!roundFromId) {
      setRoundFromBorderColor('red');
    }
    if (!roundToId) {
      setRoundToBorderColor('red');
    }
    if (!roundFromId || !roundToId) {
      return;
    }

    setRequestSeasonPDF(true);
    refreshSeasonPdf();
  };

  return (
    <>
      {league?.insights?.seasonSummarySeniorClubPdf && (
        <BarMenu>
          <BarButton onPress={downloadSeasonPDF}>
            <DownloadText style={{ color: colors.headfirst.green }}>Print PDF</DownloadText>
          </BarButton>
          <View style={{ flex: 1 }}></View>
          <DropDownList
            placeholder="To Round"
            items={roundItems}
            isActive={activeDropDown == 'rounds2'}
            onOpen={() => {
              setActiveDropDown('rounds');
            }}
            value={roundToId}
            setValue={setRoundToId}
            onChangeValue={(roundId) => {
              setRoundToId(roundId);
            }}
            style={{
              marginLeft: 8,
              borderColor: `${roundToBorderColor}`
            }}
            containerStyle={{
              width: 200, marginHorizontal: 4
            }}
          />
          <DropDownList
            placeholder="From Round"
            items={roundItems}
            isActive={activeDropDown == 'rounds3'}
            onOpen={() => {
              setActiveDropDown('rounds');
            }}
            value={roundFromId}
            onChangeValue={(roundId) => {
              setRoundFromId(roundId);
            }}
            style={{
              marginLeft: 8,
              borderColor: `${roundFromBorderColor}`
            }}
            containerStyle={{
              width: 200, marginHorizontal: 4
            }}
          />
          <BarTitleText>Season {seasonName} Summary - Senior Clubs</BarTitleText>
        </BarMenu>
      )}
    </>
  )
}